/* eslint-disable import/no-named-as-default */
import { unicodeToLatin } from './unicode.utils';
import { ConfigurationApi } from '@app/shared/models/configuration/configuration.model';
import { LocaleUtils, timeZoneCountryInfo } from '@app/shared/utils/locale.utils';

import {
  getCountryCodeFromDomainURL,
  getCountryNameFromSubdomain,
} from '@app/shared/data/subdomains.data';
import { getCountryInfo } from '@app/shared/data/countries.data';
import { getLanguageNameInfo } from '@app/shared/data/language-names.data';

import { WindowUtils } from '@app/shared/utils/window.util';
import { CONFIGURATION } from '@app/akita/configuration/models/configuration.model';
import { Params } from '@angular/router';
import { environment } from '@environments/environment';
import { parseBigIntBase } from './big-int.utils';
import JSBI from 'jsbi';
import {
  generateProductUrlSlug,
  Product,
} from '@app/akita/api/products/models/product.model';

import { SafeUrl } from '@angular/platform-browser';
import { SUPPORTED_LANGUAGES } from '@app/core/services/popsy-transloco-http-loader.service';
import { HREF_DOMAIN_COUNTRY } from '../services/meta.service';

export const BASE_10 = 10;
export const BASE_16 = 16;
export const BASE_62 = 62;
export const BASE_36 = 36;

/* const SUPPORTED_COUNTRY_TO_LOCALE: { [key: string]: string } = {
  es: 'es',
  bo: 'es',
  cl: 'es',
  co: 'es',
  cr: 'es',
  do: 'es',
  ec: 'es',
  sv: 'es',
  gt: 'es',
  hn: 'es',
  mx: 'es',
  ni: 'es',
  pa: 'es',
  py: 'es',
  pe: 'es',
  pr: 'es',
  uy: 'es',
  ve: 'es',
  fr: 'fr',
  be: 'fr',
  lu: 'fr',
  ch: 'fr',
  sa: 'ar',
  dz: 'ar',
  bh: 'ar',
  eg: 'ar',
  iq: 'ar',
  jo: 'ar',
  kw: 'ar',
  lb: 'ar',
  ly: 'ar',
  ma: 'ar',
  om: 'ar',
  qa: 'ar',
  sy: 'ar',
  tn: 'ar',
  ae: 'ar',
  ye: 'ar',
  pt: 'pt',
  br: 'pt',
  en: 'en',
  au: 'en',
  bz: 'en',
  ca: 'en',
  ie: 'en',
  jm: 'en',
  nz: 'en',
  za: 'en',
  tt: 'en',
  gb: 'en',
  us: 'en',
};
 */
const CHARSET: Array<string> = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '_',
];

export interface SentryLocaleMetadata {
  requestedCode: string;
  hasSubDomain: string;
  hasLangParam: string;
  hasQueryParam: string;
  hasBrowser: string;
  hasTimeZone: string;
  hasDateLocale: string;
  chosenLanguage: string;
}

export type CountryDetectedMetadataSource =
  | 'subdomain'
  | 'domain'
  | 'param'
  | 'timezone'
  | 'date'
  | 'locale'
  | 'fallback';
export interface CountryDetectedMetadata {
  country: string | null;
  source: CountryDetectedMetadataSource;
  lat?: number | null;
  lng?: number | null;
}
export type SentryMetadataCallback = (metadata: SentryLocaleMetadata) => void;

export const RTL_LANGUAGES: Array<string> = [
  'ar',
  'ara',
  'arc',
  'ae',
  'ave',
  'egy',
  'he',
  'heb',
  'nqo',
  'pal',
  'phn',
  'sam',
  'syc',
  'syr',
  'fa',
  'per',
  'fas',
  'ku',
  'kur',
];

export const TRANSLATED_URLS: { [text: string]: { [lang: string]: string } } = {
  'privacy-policy': {
    en: 'privacy-policy',
    /*     pt: 'politica-de-privacidade',
    es: 'politica-de-privacidad', */
  },
  'safety-tips': {
    en: 'safety-tips',
    /*     pt: 'dicas-de-seguranca',
    es: 'consejos-de-seguridad', */
  },
  'terms-conditions': {
    en: 'terms-conditions',
    /*     pt: 'termos-e-condicoes',
    es: 'terminos-y-condiciones',
    fr: 'modalites-et-conditions', */
  },
  'refund-policy': {
    en: 'refund-policy',
    /*     pt: 'politica-de-retorno',
    es: 'politica-de-devolucion', */
  },
  'terms-of-use': {
    en: 'terms-of-use',
    /*     pt: 'termos-de-uso',
    es: 'condiciones-de-uso', */
  },
  welcome: {
    en: 'welcome',
    /*     pt: 'bem-vindo',
    es: 'bienvenido', */
  },
};

/**
 * Function that receives a URL path in english to an information page (and a language code)
 * and returns the translated version of it.
 * @param {string} path The url path to translate
 * @param {string} language The language code to translate to
 * @returns {string} The translated path (if no translation available, the same path is returned)
 */
export const getTranslatedURI = (path: string, language: string): string => {
  if (TRANSLATED_URLS && TRANSLATED_URLS[path] && TRANSLATED_URLS[path][language]) {
    return TRANSLATED_URLS[path][language];
  }
  return path;
};

/**
 * Function that receives a name (with spaces and special characters)
 * And returns a URL / SEO friendly name (used for category URLs)
 * @param {string} name The name to clean
 * @param {boolean} toLatin If this property is true, it will try to replace UTF characters with their latin counterparts
 * @returns {string} The URL Friendly name
 */
export const cleanNameForURL = (name: string | null, toLatin: boolean | null): string => {
  let cleanName = `${name || ''}`;
  if (toLatin) {
    cleanName = unicodeToLatin(`${name || ''}`);
  }
  return (
    (cleanName || '')
      .toLowerCase()
      .replace(/(\W+)/gi, '-')
      // Avoid Duplicated dashes
      .replace(/(-+)/gim, '-')
      // Avoid ending with a dash
      .replace(/(-+$)/gim, '') || '-'
  );
};

/**
 * Helper function used to get a Map of Parameters from a URL in String Format
 * @param {string} url The URL in String Format
 * @returns {{}} Object with the URL parameters
 */
export const getUrlParams = (url = ``): Params | null => {
  if (`${url || ''}`.includes(`?`)) {
    const hashes: Array<string> = url.slice(url.indexOf(`?`) + 1).split(`&`);

    const out: Record<string, string> = {};
    for (const hash of hashes) {
      const [key, val] = hash.split(`=`);
      out[key] = decodeURIComponent(val || '');
    }
    return out;
  }
  return null;
};

export const isSubdomainRedirectionNeeded = (
  currentCountry: string,
  url?: string | null
): any | null => {
  const hostnameParts = (url || WindowUtils.getLocationHref() || '')
    // Remove the protocol
    .replace(/(http|https):\/\//g, '')
    // Replace the IP address (if any) with `popsy.app`
    .replace(/((?:\d{1,3}\.){3}\d{1,3})(:\d{1,6})?/g, 'popsy.app')
    // Split the domain in parts
    .split('.');

  let subDomain = '';
  let devArea = '';
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (hostnameParts[0] && hostnameParts[0].length === 2) {
    subDomain = (hostnameParts[0] || '').toLowerCase();
  } else {
    const devSubdomain = `${hostnameParts[0] || ''}`.match(
      /([A-z]{2}-)?(dev|staging|prod)/g
    );
    if (devSubdomain && devSubdomain.length > 0) {
      subDomain = (
        devSubdomain[0].replace(/-?(dev|prod|staging)/g, '') || ''
      ).toLowerCase();
      devArea = devSubdomain[0].replace(/[A-z]{2}-/g, '');
    }
  }

  const isAvailable = Boolean(getCountryNameFromSubdomain(currentCountry));
  if ((subDomain || 'us') !== (currentCountry || '').toLowerCase() && isAvailable) {
    const destinationCountry = (currentCountry || '').toLowerCase();
    let redirectUri = (url || WindowUtils.getLocationHref() || '')
      // Replace the IP address (if any) with `popsy.app`
      .replace(/((?:\d{1,3}\.){3}\d{1,3})(:\d{1,6})?/g, 'popsy.app');
    let replaceString = `https://${destinationCountry}.popsy.app`;

    if (destinationCountry === 'us' && devArea) {
      replaceString = `https://${devArea}.popsy.app`;
    } else if (destinationCountry === 'us') {
      replaceString = `https://us.popsy.app`;
    } else if (devArea) {
      replaceString = `https://${destinationCountry}-${devArea}.popsy.app`;
    }

    redirectUri = redirectUri.replace(
      /(http|https):\/\/(\S*?\.)?(popsy.app|mypopsy.com)/gi,
      replaceString
    );
    return {
      sourceName: subDomain ? getCountryNameFromSubdomain(subDomain) : 'United States',
      sourceCountry: subDomain || 'us',
      destinationName: destinationCountry
        ? getCountryNameFromSubdomain(destinationCountry)
        : 'United States',
      destinationCountry: destinationCountry,
      redirectUri: replaceString,
      fullRedirectUri: redirectUri,
    };
  }
  return null;
};

export const getLanguageMapping = (
  langCode: string,
  returnCountry?: boolean | null
): string => {
  const langTokens = (langCode || '').toLowerCase().split('-');
  const code = langTokens[0];
  const country = langTokens.length > 1 ? langTokens[1] : '';
  if (country) {
    const countryInfo = getCountryInfo(country);
    if (countryInfo && returnCountry) {
      return country;
    } else if ((countryInfo?.languages || []).includes(code) && !returnCountry) {
      return code;
    }
  }

  if (returnCountry) {
    return '';
  } else if (code) {
    for (const countryCode of Object.keys(CONFIGURATION.languages || {})) {
      if (CONFIGURATION.languages[countryCode] === code) {
        return CONFIGURATION.languages[countryCode];
      }
    }
  }

  return CONFIGURATION.languages[code] || '';
};

export const isLanguageRtl = (lang: string): boolean => RTL_LANGUAGES.includes(lang);

export const getLangSubdomain = (
  url?: string | null,
  returnCountry?: boolean | null
): string | null => {
  let hostname = '';
  try {
    if (window.location && WindowUtils.getLocationHostname()) {
      hostname = WindowUtils.getLocationHostname();
    }
  } catch {
    // Accessing window will throw error when using in Server Side
  }

  const hostnameParts: Array<string> = (url || hostname)
    // Remove the protocol from the URL
    .replace('http://', '')
    .replace('https://', '')
    // Replace the IP address (if any) with `popsy.app`
    .replace(/((?:\d{1,3}\.){3}\d{1,3})(:\d{1,6})?/g, 'popsy.app')
    // Split the domain in parts
    .split('.');
  let subdomain = `${hostnameParts[0] || ''}`;

  if (subdomain.includes('-')) {
    subdomain = subdomain.split('-')[0];
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (hostnameParts.length >= 3 && subdomain.length <= 2) {
    if (CONFIGURATION.languages[subdomain] && !returnCountry) {
      return CONFIGURATION.languages[subdomain];
    }
    return subdomain;
  }
  return null;
};

export const getLangInPath = (
  url?: string | null,
  isCountry?: boolean | null
): string | null => {
  let hostname = '';
  try {
    if (WindowUtils.getLocationPathname()) {
      hostname = WindowUtils.getLocationPathname();
    }
  } catch {
    // Accessing window will throw error when using in Server Side
  }

  const pathParts: Array<string> = (url || hostname || '')
    // Remove the protocol from the URL
    .replace('http://', '')
    .replace('https://', '')
    // Replace the IP address (if any) with `popsy.app`
    .replace(/((?:\d{1,3}\.){3}\d{1,3})(:\d{1,6})?/g, 'popsy.app')
    // Split the domain in parts
    // eslint-disable-next-line no-useless-escape
    .split(/[&/?]/g);

  let locale: string | null = null;
  let country: string | null = null;

  if (
    pathParts.length > 0 &&
    pathParts[1] &&
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    pathParts[1].length === 2 &&
    getLanguageNameInfo(pathParts[1])
  ) {
    // WE DO NOT INFERE LANGUAGE FROM COUNTRY
    /*  locale = pathParts[1];

    const localeMatch = SUPPORTED_COUNTRY_TO_LOCALE[locale || ''];
    if (localeMatch) {
      country = `${locale}`;
      locale = `${localeMatch}`;
    } */
    if (SUPPORTED_LANGUAGES.includes(pathParts[1])) {
      locale = pathParts[1];
    }
  } else if (
    pathParts.length > 0 &&
    pathParts[1] &&
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    pathParts[1].length === 5
  ) {
    const langTokens = pathParts[1].split('-');
    const detectedLocale = `${(langTokens[0] || '').toLowerCase()}`;
    const detectedCountry = `${(langTokens[1] || '').toLowerCase()}`;

    if (getLanguageNameInfo(detectedLocale)) {
      country = `${detectedCountry}`;
      locale = `${detectedLocale}`;
    }
  }

  return isCountry === true ? country : locale;
};

export const getLangInQueryParams = (url?: string | null): string => {
  let hostname = '';
  try {
    if (WindowUtils.getLocationPathname()) {
      hostname = WindowUtils.getLocationPathname();
    }
  } catch {
    // Accessing window will throw error when using in Server Side
  }

  let locale: string | null = null;
  try {
    const currentURL: URL = new URL(`${url || hostname || ''}`);
    const paramsList = currentURL.search.replace('?', '').split('&');

    for (const param of paramsList) {
      const paramValue = `${param || ''}`.toLowerCase().split('=');
      if (
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        paramValue.length >= 2 &&
        paramValue[0] === 'lang' &&
        (paramValue[1] === 'en' ||
          paramValue[1] === 'pt' ||
          paramValue[1] === 'ar' ||
          paramValue[1] === 'fr' ||
          paramValue[1] === 'es')
      ) {
        locale = paramValue[1];
        break;
      }
    }
  } catch {
    /** URL not valid */
  }

  return locale || '';
};

export const getLanguageCode = (
  requestedCode: string,
  url?: string | null,
  isBrowser?: boolean | null,
  metadataCallback?: SentryMetadataCallback | null
): string => {
  // get the browser locale
  const browserLanguage = LocaleUtils.browserLanguage;
  const dateLocale = LocaleUtils.dateLocale;

  let code = requestedCode;
  let hasSubDomain: string | null = null;
  let hasLangParam: string | null = '-';
  let hasBrowser = '-';
  let hasDateLocale = '-';
  let hasQueryParam = '-';

  // If no browser locale, try to get the locale from timeZone
  if (isBrowser && dateLocale && !browserLanguage) {
    hasDateLocale = getLanguageMapping(dateLocale);
    code = hasDateLocale;
  }

  // check that the hostname is a domain with a sub-domain
  // - has at least 3 parts, example: [ us, mypopsy, com ] or [ br-dev, popsy, app ]
  // then check if the first sub-domain matches one of the languages in the available languages map.
  hasSubDomain = getLangSubdomain(url);
  if (hasSubDomain) {
    code = hasSubDomain;
  }

  // If the language is set in the browser (use it)
  if (isBrowser && browserLanguage) {
    hasBrowser = getLanguageMapping(browserLanguage);
    code = hasBrowser;
  }

  // Check if the url contains a language in the URL Path
  // If this is the case, the language from the URL path takes precedence
  // example: popsy.app/br/cars-category
  hasLangParam = getLangInPath(url);
  if (hasLangParam) {
    code = hasLangParam;
  }

  // Check if the url contains a language in the URL Query Params
  // If this is the case, the language from the URL query param takes precedence
  // example: popsy.app/br/cars-category?lang=es
  hasQueryParam = getLangInQueryParams(url);
  if (hasQueryParam) {
    code = hasQueryParam;
  }

  const chosenLanguage = code || requestedCode || 'en';

  // Save Context for sentry (useful for Debugging)
  if (metadataCallback) {
    metadataCallback({
      requestedCode: requestedCode,
      hasSubDomain: hasSubDomain || '-',
      hasLangParam: hasLangParam || '-',
      hasQueryParam: hasQueryParam,
      hasBrowser: hasBrowser,
      hasTimeZone: '-',
      hasDateLocale: hasDateLocale,
      chosenLanguage: chosenLanguage,
    });
  }

  return chosenLanguage;
};

export const getCountryCode = (
  requestedCode: string,
  url?: string | null,
  metadataCallback?: SentryMetadataCallback | null
): CountryDetectedMetadata => {
  // get the browser locale
  const countryInfo = timeZoneCountryInfo();
  const browserLanguage = LocaleUtils.browserLanguage;
  const dateLocale = LocaleUtils.dateLocale;

  let code = requestedCode;
  let source: CountryDetectedMetadataSource = 'fallback';
  let hasSubDomain: string | null = null;
  let hasDomain: string | null = null;
  let hasLangParam: string | null = '-';
  let hasTimeZone = '-';
  let hasBrowser = '-';
  let hasDateLocale = '-';
  let lat: number | null = null;
  let lng: number | null = null;

  // If the language is set in the browser (use it)
  if (browserLanguage) {
    hasBrowser = getLanguageMapping(browserLanguage, true);
    code = hasBrowser;
    source = 'locale';
  }

  // If the language is NOT set in the browser (try to use the date locale)
  if (dateLocale) {
    hasDateLocale = getLanguageMapping(dateLocale, true);
    if (!browserLanguage) {
      code = hasDateLocale;
      source = 'date';
    }
  }

  // If the timezone is set, use it
  if (countryInfo) {
    hasTimeZone = countryInfo.country;
    code = hasTimeZone;
    source = 'timezone';
    lat = countryInfo.lat;
    lng = countryInfo.lng;
  }

  // check that the hostname is a custom domain
  // supported on DEFAULT_DOMAIN_COUNTRY
  hasDomain = getCountryCodeFromDomainURL(url);
  if (hasDomain) {
    code = hasDomain;
    source = 'domain';
  }

  // check that the hostname is a domain with a sub-domain
  // - has at least 3 parts, example: [ us, mypopsy, com ] or [ br-dev, popsy, app ]
  hasSubDomain = getLangSubdomain(url, true);
  if (hasSubDomain) {
    code = hasSubDomain;
    source = 'subdomain';
  }

  // Check if the url contains a language in the URL Path
  // If this is the case, the language from the URL path takes precedence
  // example: popsy.app/br/cars-category
  hasLangParam = getLangInPath(url, true);
  if (hasLangParam) {
    code = hasLangParam;
    source = 'param';
  }

  const choosenCountry: string = code || requestedCode;

  // Save Context for sentry (useful for Debugging)
  if (metadataCallback) {
    metadataCallback({
      requestedCode: requestedCode,
      hasSubDomain: hasSubDomain || '-',
      hasLangParam: hasLangParam || '-',
      hasQueryParam: '-',
      hasBrowser: hasBrowser,
      hasTimeZone: hasTimeZone,
      hasDateLocale: hasDateLocale,
      chosenLanguage: choosenCountry,
    });
  }

  return {
    country: choosenCountry,
    source: source,
    lat: lat,
    lng: lng,
  };
};

export const getCurrentHost = (): string => {
  let protocol = '';
  let hostname = '';
  try {
    const locationProtocol = WindowUtils.getLocationProtocol();
    const locationHostname = WindowUtils.getLocationHost();
    if (window.location && locationHostname) {
      protocol = `${locationProtocol || 'https'}`.replace(/:/g, '').replace(/\//g, '');
      hostname = locationHostname;
    }
  } catch {
    // Accessing window will throw error when using in Server Side
  }

  return `${protocol || 'https'}://${hostname || 'www.popsy.app'}`;
};

// deprecated
/* export const getBaseCanonicalURL = (
  country?: string | null,
  url?: string | null
): string => {
  let hostname = '';
  try {
    const locationHostname = WindowUtils.getLocationHostname();
    if (window.location && locationHostname) {
      hostname = locationHostname;
    }
  } catch {
    // Accessing window will throw error when using in Server Side
  }

  const currentURL: URL = new URL(`${url || hostname || ''}`);

  const ipMatches = `${currentURL?.hostname || ''}`.match(/((?:\d{1,3}\.){3}\d{1,3})/);
  if (
    currentURL.hostname === 'localhost' ||
    ipMatches ||
    currentURL.hostname?.indexOf('ngrok.io') !== -1
  ) {
    if (country) {
      return `${currentURL.origin}/${country.toLowerCase()}`;
    }
    return `${currentURL.origin}`;
  }

  const hostnameParts: Array<string> = currentURL.hostname.split('.');
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (hostnameParts.length >= 3) {
    let subdomain = `${hostnameParts[0] || ''}`;
    if (subdomain.includes('-')) {
      let tokens = subdomain.split('-');
      if (country) {
        tokens[0] = `${country}`.toLowerCase();
      } else {
        tokens = tokens.slice(1);
      }
      subdomain = tokens.join('-');
    } else if (subdomain === 'staging' || subdomain === 'prod' || subdomain === 'dev') {
      if (country) {
        subdomain = `${country}-${subdomain}`.toLowerCase();
      }
    } else {
      subdomain = `${country || 'www'}`.toLowerCase();
    }
    return `${currentURL.origin}`.replace(hostnameParts[0], subdomain);
  }

  currentURL.hostname = `${country || 'www'}.${currentURL.hostname}`.toLowerCase();
  return `${currentURL.origin}`;
}; */

export const getCanonicalProductUrl = (product?: Product | null): SafeUrl | null => {
  let url = `${WindowUtils.getLocationHref() || ''}`;

  if (product && product.country) {
    const subdomain = product.country.toLowerCase();
    const language = product.language.toLowerCase();
    const productSlug = generateProductUrlSlug(product);

    // new domains support
    for (const [key, value] of Object.entries(HREF_DOMAIN_COUNTRY)) {
      if (subdomain === key) {
        return `https://${value}/${language}-${subdomain}/p/${productSlug}`;
      }
    }
    // base domain default
    url = `https://www.popsy.app/${language}-${subdomain}/p/${productSlug}`;
  }
  return url;
};

export const getBaseCanonicalUrl = (url: string): SafeUrl | null => {
  /* url = url;   || `${WindowUtils.getLocationHref() || ''}`; */
  const currentURL: URL = new URL(`${url || ''}`);
  const localeFragment = currentURL.pathname.split('/')[1];
  const language = localeFragment.split('-')[0] || 'en';
  const country = localeFragment.split('-')[1] || 'sa';
  if (country && language) {
    const subdomain = country.toLowerCase();

    // new domains support
    for (const [key, value] of Object.entries(HREF_DOMAIN_COUNTRY)) {
      if (subdomain === key) {
        return `https://${value}${currentURL.pathname}${currentURL.search}`;
      }
    }
    // base domain default
    url = `https://www.popsy.app/${language}-${subdomain}/p/aqui`;
  }
  return url;
};
/**
 * Function used to build the API URL from the configuration.
 *
 * @param {string} endpoint The API path to use
 * @param {boolean} fromRoot if this is true, the url will not have /api/v1
 * @returns {string} The assembled URL
 */
export const getApiEndpoint = (
  endpoint: string,
  fromRoot?: boolean,
  url?: string
): string => {
  const api: ConfigurationApi = CONFIGURATION.api;
  const path = fromRoot ? '' : `${api.path}${api.version}`;

  // If URL is set return absolute URL (http://domain/api/v1/...)
  if (api.url) {
    const port: string = api.port > 0 ? `:${api.port}` : '';
    let hostname = '';
    try {
      if (window.location && WindowUtils.getLocationHostname()) {
        hostname = WindowUtils.getLocationHostname();
      }
    } catch {
      // Accessing window will throw error when using in Server Side
    }

    const hostnameParts: Array<string> = (url || hostname)
      // Remove the protocol from the URL
      .replace('http://', '')
      .replace('https://', '')
      // Replace the IP address (if any) with `popsy.app`
      .replace(/((?:\d{1,3}\.){3}\d{1,3})(:\d{1,6})?/g, 'popsy.app')
      // Split the domain in parts
      .split('.');
    let subdomain = `${hostnameParts[0] || ''}`;

    if (subdomain.includes('-')) {
      subdomain = subdomain.split('-')[1];
    }

    // if the subdomain is dev, staging or prod use a specific api (for easy change in dev area)
    switch (subdomain) {
      case 'staging': {
        return `${api.protocol}api-staging.popsy.app${port}${path}/${endpoint}`;
      }
      case 'dev': {
        return `${api.protocol}api-dev.popsy.app${port}${path}/${endpoint}`;
      }
      case 'prod': {
        return `${api.protocol}api.popsy.app${port}${path}/${endpoint}`;
      }
    }

    return `${api.protocol}${api.url}${port}${path}/${endpoint}`;
  }

  // If URL not set, return relative URL (/api/v1/...)
  return `${path}/${endpoint}`;
};

export const getURIFromBaseURL = (baseURL: string, endpoint: string): string => {
  // If URL is set return absolute URL (http://domain/api/v1/...)
  if (baseURL) {
    let hostname = '';
    try {
      if (window.location && WindowUtils.getLocationHostname()) {
        hostname = WindowUtils.getLocationHostname();
      }
    } catch {
      // Accessing window will throw error when using in Server Side
    }

    const hostnameParts: Array<string> = `${hostname || ''}`
      // Remove the protocol from the URL
      .replace('http://', '')
      .replace('https://', '')
      // Replace the IP address (if any) with `popsy.app`
      .replace('localhost', 'popsy.app')
      .replace(/((?:\d{1,3}\.){3}\d{1,3})(:\d{1,6})?/g, 'popsy.app')
      // Split the domain in parts
      .split('.');
    let subdomain = `${hostnameParts[0] || ''}`;

    if (subdomain.includes('-')) {
      subdomain = subdomain.split('-')[1];
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (hostnameParts.length >= 3 && subdomain) {
      return `https://${subdomain}.popsy.app/${endpoint}`;
    }

    return `${baseURL}/${endpoint}`;
  }

  // If URL not set, return relative URL (/api/v1/...)
  return `/${endpoint}`;
};

/**
 * Function used to get the Firebase Config based on the SubDomain (when rehusing the same server for dev)
 *
 * @returns {FirebaseOptions} The firebase Config
 */
export const getFirebaseConfig = (): any => {
  try {
    const hostnameParts = ((window.location || { hostname: '' }).hostname || '').split(
      '.'
    );

    // if the subdomain is dev, staging or prod use a specific api (for easy change in dev area)
    switch (hostnameParts[0]) {
      case 'staging':
        return {
          apiKey: 'AIzaSyAR9WAah_Pcax8NWLFtD6xKUjCyfHp9b8g',
          authDomain: 'popsygae-staging.firebaseapp.com',
          databaseURL: 'https://popsygae-staging.firebaseio.com',
          projectId: 'popsygae-staging',
          storageBucket: 'popsygae-staging.appspot.com',
          messagingSenderId: '939751172442',
          appId: '1:939751172442:web:0a32f6a5277a03c525ab63',
          measurementId: 'G-HH6C421KMP',
        };
      case 'prod':
        return {
          apiKey: 'AIzaSyAYqkgWAUgReGdZJFwp9T2qFppkTkXjRUU',
          authDomain: 'popsygae.firebaseapp.com',
          databaseURL: 'https://popsygae.firebaseio.com',
          projectId: 'popsygae',
          storageBucket: 'popsygae.appspot.com',
          messagingSenderId: '623123402103',
          appId: '1:623123402103:web:113919f03beba40e02e572',
          measurementId: 'G-XRNEBP8N5C',
        };
      case 'dev':
        return {
          apiKey: 'AIzaSyD91zJcjmocLffLw1rd1mjMRNWl4vcQvjI',
          authDomain: 'popsygae-dev.firebaseapp.com',
          databaseURL: 'https://popsygae-dev.firebaseio.com',
          projectId: 'popsygae-dev',
          storageBucket: 'popsygae-dev.appspot.com',
          messagingSenderId: '693805660182',
          appId: '1:693805660182:web:b03cdf83c1578105359d37',
          measurementId: 'G-XSLLL760JX',
        };
    }
  } catch (err) {
    // SSR: Window not Supported
  }

  return CONFIGURATION.firebase;
};

/**
 * Function used to get the Google Maps API Key based on the SubDomain (when rehusing the same server for dev)
 *
 * @returns {string} The google maps api key
 */
export const getGoogleMapsApiToken = (): string => {
  try {
    const hostnameParts = ((window.location || { hostname: '' }).hostname || '').split(
      '.'
    );

    // if the subdomain is dev, staging or prod use a specific api (for easy change in dev area)
    switch (hostnameParts[0]) {
      case 'staging':
        return 'AIzaSyCHJ2M3LT9UvWqfPSg2TTW-C7Lkwh3eJ-0';
      case 'prod':
        return 'AIzaSyAEzD4ZBaCSwNWZaa9AmeVSWzfYP-3V5eU';
      case 'dev':
        return 'AIzaSyD2Rbis5E8CIlawIq4s4gzQqD42kwZa1f4';
    }
  } catch (err) {
    // SSR: window not supported
  }

  return CONFIGURATION.gmapsApiKey;
};

export const getEnviromentType = (): 'staging' | 'prod' | 'dev' => {
  try {
    const hostnameParts = ((window.location || { hostname: '' }).hostname || '').split(
      '.'
    );

    // if the subdomain is dev, staging or prod use a specific api (for easy change in dev area)
    switch (hostnameParts[0]) {
      case 'staging':
        return 'staging';
      case 'prod':
        return 'prod';
      case 'dev':
        return 'dev';
    }
  } catch (err) {
    // SSR: window not supported
  }

  if (environment.production) {
    return 'prod';
  }

  return 'staging';
};

/**
 * Function used to get the Apple Client ID based on the SubDomain (when rehusing the same server for dev)
 *
 * @returns {string} The Apple Client ID
 */
export const getAppleClientID = (): string => {
  try {
    const hostnameParts = ((window.location || { hostname: '' }).hostname || '').split(
      '.'
    );

    // if the subdomain is dev, staging or prod use a specific api (for easy change in dev area)
    switch (hostnameParts[0]) {
      case 'staging':
        return 'app.popsy.buysell.staging.service';
      case 'prod':
        return 'app.popsy.buysell.service';
      case 'dev':
        return 'app.popsy.buysell.debug.service';
    }
  } catch {
    // In SSR there is no window
  }

  return environment.apple.clientId;
};

/**
 * Function used to get the Google Client ID based on the SubDomain (when rehusing the same server for dev)
 *
 * @returns {string} The Google Client ID
 */
export const getGoogleClientID = (): string => {
  try {
    const hostnameParts = ((window.location || { hostname: '' }).hostname || '').split(
      '.'
    );

    // if the subdomain is dev, staging or prod use a specific api (for easy change in dev area)
    switch (hostnameParts[0]) {
      case 'staging':
        return '939751172442-8gglfp40l0cp43nq1lpcp0slqnkvehpo.apps.googleusercontent.com';
      case 'prod':
        return '623123402103-ame6isegnneda535h995iuqpj5f8gu9a.apps.googleusercontent.com';
      case 'dev':
        return '939751172442-8gglfp40l0cp43nq1lpcp0slqnkvehpo.apps.googleusercontent.com';
    }
  } catch {
    // In SSR there is no window
  }

  return environment.google.clientId;
};

export const encode = (text: string, base?: number): string => {
  // If text is empty, the result is empty string
  if (!text) {
    return '';
  }

  try {
    let encodedString = '';

    const bigIntBase = JSBI.BigInt(CHARSET.length);
    const bigIntegerFromText = parseBigIntBase(text, base || BASE_10);
    let result = {
      quotient: JSBI.divide(bigIntegerFromText, bigIntBase),
      remainder: JSBI.remainder(bigIntegerFromText, bigIntBase),
    };

    do {
      let quotient = result.quotient;
      let remainderAsInt = Number.parseInt(result.remainder.toString(), BASE_10);
      encodedString += CHARSET[remainderAsInt];
      result = {
        quotient: JSBI.divide(quotient, bigIntBase),
        remainder: JSBI.remainder(quotient, bigIntBase),
      };

      if (JSBI.LT(result.quotient, JSBI.BigInt(1))) {
        quotient = result.quotient;
        remainderAsInt = Number.parseInt(result.remainder.toString(), BASE_10);
        encodedString += CHARSET[remainderAsInt];
        quotient = JSBI.divide(quotient, bigIntBase);
        break;
      }
      // eslint-disable-next-line no-constant-condition
    } while (true);

    // Reverse the string
    return [...(encodedString || '')].reverse().join('');
  } catch {
    return text;
  }
};

export const decode = (text: string, base?: number): string => {
  try {
    const textLength = (text || '').length;
    let result = JSBI.BigInt(0);
    let m = JSBI.BigInt(1);
    for (let i = 0; i < textLength; i += 1) {
      const char = text[textLength - 1 - i];
      let tmp: any;
      if (char === '_') {
        tmp = JSBI.BigInt(BASE_62);
      } else if (char <= '9') {
        tmp = JSBI.BigInt(char.charCodeAt(0) - '0'.charCodeAt(0));
      } else if (char <= 'Z') {
        tmp = JSBI.BigInt(char.charCodeAt(0) - 'A'.charCodeAt(0) + BASE_10);
      } else if (char <= 'z') {
        tmp = JSBI.BigInt(char.charCodeAt(0) - 'a'.charCodeAt(0) + BASE_36);
      }
      result = JSBI.add(result, JSBI.multiply(tmp, m));
      m = JSBI.multiply(m, JSBI.BigInt(CHARSET.length));
    }
    return JSBI.BigInt(result).toString(base);
  } catch {
    return text || '';
  }
};

/**
 * Gets a SEO friendly string to use in the URL as an ID
 * @param {string} title the readable text
 * @param {string} id the has to convert to Base65
 * @returns {String} The SEO Friendly URL.
 */
export const generateURL = (id: string, title?: string | null): string => {
  const out = new Array(0);
  // Replace any character that is not a letter or number with a `-`
  const friendlyTitle = cleanNameForURL(title || '', true)
    .toLowerCase()
    .replace(/[^\w-]/g, '-');
  if (friendlyTitle) {
    out.push(friendlyTitle);
  }

  // Encode the ID into a Base63 String
  const smallHash = encode(id, BASE_16);
  if (smallHash) {
    out.push(smallHash);
  }

  // Return the SEO Friendly url path
  return out.join('-');
};

/**
 * Gets the parsed Model field to be used on the URL
 * @param {string} model The model to parse
 * @returns {string} The parsed model fragment
 *
 */
export const toUrlSlug = (model: string): string =>
  model
    .trim()
    .toLowerCase()
    .replace(/-/g, '~') // Escape original hyphens
    .replace(/\s+/g, '-'); // Replace spaces with hyphens

export const fromUrlSlug = (slug: string): string =>
  slug
    .replace(/-/g, ' ') // Replace hyphens with spaces
    .replace(/~/g, '-') // Restore original hyphens
    .split(' ') // Split into words
    .map((word) => {
      if (word.toLowerCase() === 'iphone') {
        return 'iPhone';
      }
      // Capitalize first letter, lowercase rest
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
